import React, { CSSProperties } from 'react'
import Lottie, { LottieProps, Options } from 'react-lottie'

export type OptionsWithoutAnimationData = Omit<Options, 'animationData'>
export type PropsWithCustomOptions = Partial<Omit<LottieProps, 'options'> & OptionsWithoutAnimationData>

export interface AnimationProps extends PropsWithCustomOptions {
  /**
   * Optional css styling for the animation
   */
  style?: CSSProperties
  /**
   * Lottie options for the animation
   */
  options?: OptionsWithoutAnimationData
}

export interface AnimationPropsWithData extends AnimationProps {
  /**
   * Raw json animation data from e.g Haiku or After Effects
   */
  data: Record<string, unknown>
}

/**
 * Wrapper for react-lottie animation library providing sane default values for the library.
 * Lottie can play animations that can be produced with e.g. Haiku or After Effects
 *
 * To use the animation just provide the raw json animation data as animationData
 * prop with width and height and you're good to go!
 */
const Animation: React.FC<AnimationPropsWithData> = ({ options, ...props }) => {
  /**
   * See https://github.com/chenqingspring/react-lottie,
   * https://www.npmjs.com/package/lottie-web#other-loading-options
   * and https://github.com/airbnb/lottie-web/wiki/Renderer-Settings for options
   */
  const defaultOptions: Options = {
    animationData: props.data,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={{ ...defaultOptions, ...options }} {...props} />
}

Animation.defaultProps = {
  width: 60,
  height: 60,
  options: {},
  isClickToPauseDisabled: true,
}

/** @component */
export default Animation
