(function webpackUniversalModuleDefinition(root, factory) {
	//CommonJS2 Comment
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"));
	//AMD Comment
	else if(typeof define === 'function' && define.amd)
		define("PostiDigital", ["React", "PropTypes"], factory);
	//CommonJS Comment
	else if(typeof exports === 'object')
		exports["PostiDigital"] = factory(require("react"), require("prop-types"));
	//Root Comment
	else
		root["PostiDigital"] = root["PostiDigital"] || {}, root["PostiDigital"]["animations"] = factory(root["React"], root["PropTypes"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__84639__, __WEBPACK_EXTERNAL_MODULE__23343__) {
return 